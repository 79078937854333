// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//ng buid --prod
export const environment = {
  production: false,
  API_URL: "https://api.mobgas.co/api/v1",
  // API_URL: "http://localhost:3023/api/v1",
  //  API_URL: "http://52.47.187.82:3005/api/v1",
  //  API_URL: "http://13.234.218.36:3001/api/v1",
  firebase: {
    apiKey: "AIzaSyAz4UABQF5h9ZHQxBMj-ZZXyIuQxfx1HOE",
    authDomain: "mob-gas.firebaseapp.com",
    databaseURL: "https://mob-gas.firebaseio.com",
    projectId: "mob-gas",
    storageBucket: "mob-gas.appspot.com",
    messagingSenderId: "313480965436",
    appId: "1:313480965436:web:2e6a486913c30fef578239",
  },
  apiUrlVendor:  "https://vendor.mobgas.co/",
  Pay_stack_key: "pk_live_8a7f8c5e29e9703eb6979a46414392a54c63e049",
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
