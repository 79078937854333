import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { ReplaySubject, Subject, BehaviorSubject } from "rxjs";
import { TokenService } from "./token.service";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { ToastrService } from "ngx-toastr";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class CoreService {
  deviceId = uuidv4();
  devicetype = "W";

  public isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  public currentUserSubject = new ReplaySubject(1);
  public currentUser = this.currentUserSubject.asObservable();

  public orderDetailsSubject = new ReplaySubject(1);
  public orderDetails = this.orderDetailsSubject.asObservable();

  public notificationListSubject = new ReplaySubject(1);
  public notificationList = this.orderDetailsSubject.asObservable();

  //for permission array
  public permissionSubject = new ReplaySubject(1);
  public permission = this.permissionSubject.asObservable();

  isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isLoading$ = this.isLoading.asObservable();

  public pageSize = 10;

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private toastrService: ToastrService
  ) {
    // this.isLoading.next(false);
  }

  showLoading() {
    this.isLoading.next(true);
  }
  hideLoading() {
    this.isLoading.next(false);
  }

  navigateTo(path: string, params = null) {
    if (params) {
      this.router.navigateByUrl(path, { queryParams: params });
    }
    this.router.navigate([path]);
  }

  purgeAuth() {
    this.tokenService.destroyToken();
    this.isAuthenticatedSubject.next(false);
    this.currentUserSubject.next();
    this.permissionSubject.next([]);
  }

  showMessge(resp) {
    switch (resp.type) {
      case "success":
        this.toastrService.success(resp.message, "Success!");
        break;
      case "warning":
        this.toastrService.warning(resp.message, "Alert!");
        break;
      case "danger":
        this.toastrService.error(resp.message, "Oops!");
        break;
      default:
        this.toastrService.info(resp.message, "Info");
        break;
    }
  }

  getYearsDropdown() {
    const SelectTruckYear = [];
    for (let i = 0; i <= 30; i++) {
      SelectTruckYear.push(new Date().getFullYear() - i);
    }
    return SelectTruckYear;
  }


}
